import { instanceAxios } from 'utils'
import { ApiRequest } from '../interface'
import { EnumSurveyStatus } from 'models'

const show = async (id: string): Promise<any> => instanceAxios.get(`/survey-manage/${id}`)
const update = async (id: string, data: ApiRequest.survey_manage.update): Promise<any> => instanceAxios.patch(`/survey-manage/${id}`, data)
const change_status = async (id: string, status: EnumSurveyStatus): Promise<any> => instanceAxios.patch(`/survey-manage/${id}/change-status`, { status })
const list_answers = async (id: string): Promise<any> => instanceAxios.get(`/survey-manage/${id}/answers`)
const list_feedbacks = async (id: string, params: ApiRequest.survey_manage.feedback_index): Promise<any> =>
  instanceAxios.get(`/survey-manage/${id}/feedbacks`, { params })
const show_feedback = async (id: string, feedback_id: string): Promise<any> => instanceAxios.get(`/survey-manage/${id}/feedbacks/${feedback_id}`)

const options_list = async (): Promise<any> => instanceAxios.get(`/survey-manage/options-list`)

const create_url_path = async (id: string, url_path: string): Promise<any> => instanceAxios.post(`/survey-manage/${id}/url-path/${url_path}`)
const remove_url_path = async (id: string, url_path: string): Promise<any> => instanceAxios.delete(`/survey-manage/${id}/url-path/${url_path}`)

const upload_content_image = async (id: string, blob: any) => {
  const form = new FormData()
  form.append('upload', blob)
  return instanceAxios.post(`/survey-manage/${id}/upload-content-image`, form)
}
export default {
  show,
  update,
  list_answers,
  list_feedbacks,
  show_feedback,
  options_list,
  change_status,
  create_url_path,
  remove_url_path,
  upload_content_image
}
