import { CustomerEntity } from 'models/customer'
import { AnswerEntity, AnswerErrors, SurveyEntity, SurveyFeedbackEntity, SurveyMode } from 'models/survey'

export interface SurveyFeedbackState {
  survey?: SurveyEntity
  answers: AnswerEntity[]
  errors: AnswerErrors
  customer?: CustomerEntity
  current_feedback?: SurveyFeedbackEntity
  mode?: SurveyMode
  is_submited: boolean
  hide_non_answer_questions?: boolean
  exclude_ids?: string[]
  thanks_params?: ThanksFormData
  thanks_template?: string
  customer_phone?: string

  exporting_thanks: boolean
  ready_to_answer: boolean

  scroll_to_first_unanswered: boolean
}

export interface ThanksFormData {
  code?: string
}

export const ThankFormFieldMapping: Record<keyof ThanksFormData, string> = { code: '{{code}}' }
